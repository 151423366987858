import React, { useEffect } from 'react'
import query from 'query-string'
import { TransitionPortal } from 'gatsby-plugin-transition-link'
import { navigate } from 'gatsby'
import { DotLoader } from 'react-spinners'
import { Text, Box, Row, Circle, Title, settings } from 'boostly-ui2'
import Shell from '../components/shell'
import styled from '@emotion/styled'
import { Arrow } from '../components/icons'
import { coverTransition } from '../transitions'

const Label = props => (
  <Title size='20px' color={settings.colors.purple}>
    {props.children}
  </Title>
)
const Input = styled.input`
  position: absolute;
  width: 100%;
  height: 40px;
  border: none;
  display: block;
  background: transparent;
  font-size: 16px;
  transition: 0.25s;
  font-family: righteous;
  &:focus {
    outline: none;
  }
`
const Highlight = styled.div`
  position: absolute;
  background-color: ${settings.colors.purple};
  height: 46px;
  left: 50%;
  transition: 0.25s;
  transform: translateX(-50%);
  transform-origin: center center;
`
const highlightStyle = {
  on: {
    color: 'white',
    borderBottom: `solid 1px white`
  },
  off: {
    color: settings.colors.purple,
    borderBottom: `solid 1px ${settings.colors.purple}`
  }
}
const InputField = React.forwardRef(({ label, value, onChange, type }, ref) => {
  const [shouldHighlight, setHighlight] = React.useState(false)
  const onFocus = () => {
    setHighlight(true)
  }
  const onBlur = () => {
    setHighlight(false)
  }
  return (
    <Box height='70px'>
      <Label>{label}</Label>
      <Box position='relative' mt={2}>
        <Highlight style={{ width: shouldHighlight ? '200vw' : 0 }} />
        <Input
          {...{
            ref,
            value,
            onChange,
            type,
            onFocus,
            onBlur,
            style: shouldHighlight ? highlightStyle.on : highlightStyle.off
          }}
        />
      </Box>
    </Box>
  )
})
const HorizontalSpace = ({ multiple = 1 }) => (
  <Box height={multiple * 10 + 'px'} />
)
const isEvent = e => !!e.target
const useInput = initial => {
  const [input, setInput] = React.useState(initial)

  return [input, e => setInput(isEvent(e) ? e.target.value : e)]
}
function Login(props) {
  const [email, setEmail] = useInput('')
  const [password, setPassword] = useInput('')
  const [isLoading, setLoadingStatus] = React.useState(false)
  const emailRef = React.useRef()
  const passwordRef = React.useRef()
  const coverRef = React.useRef()
  const shellRef = React.useRef()
  useEffect(() => {
    const { email } = query.parse(props.location.search)
    if (email) {
      setEmail(email)
      passwordRef.current.focus()
    } else {
      emailRef.current.focus()
    }
  }, [])

  const onSubmit = () => {
    setLoadingStatus(true)
    setTimeout(() => {
      setLoadingStatus(false)
      routeTo('/app/stats')
    }, 1500)
  }

  const routeTo = route => {
    coverTransition({
      direction: 'up',
      seconds: 1.5,
      node: coverRef.current,
      pageNode: shellRef.current
    })
    setTimeout(() => {
      navigate(route)
    }, 1400)
  }

  return (
    <Shell ref={shellRef}>
      {({ Container }) => (
        <>
          <Container>
            <Box px={4} pt='8%' w='100%'>
              <Row x='end'>
                <Text size={6}>
                  <span role='img' aria-label='Hand waving emoji'>
                    👋
                  </span>
                </Text>
              </Row>
              <InputField
                ref={emailRef}
                label='Email'
                value={email}
                onChange={setEmail}
              />
              <HorizontalSpace multiple='3.5' />
              <InputField
                ref={passwordRef}
                label='Password'
                value={password}
                onChange={setPassword}
                type='password'
              />
              <HorizontalSpace multiple='5' />
              <Row y w='100%' space='between'>
                <Label size='24px'>Sign In</Label>
                <Circle.Button
                  onClick={onSubmit}
                  color={settings.colors.purple}
                  size='60px'
                >
                  {isLoading ? (
                    <DotLoader color='white' size={32} />
                  ) : (
                    <Arrow />
                  )}
                </Circle.Button>
              </Row>
              <TransitionPortal>
                <div
                  ref={coverRef}
                  style={{
                    position: 'fixed',
                    background: settings.colors.purple,
                    top: 0,
                    left: 0,
                    width: '100vw',
                    height: '100vh',
                    transform: 'translateY(100%)'
                  }}
                />
              </TransitionPortal>
            </Box>
          </Container>
          <Rocket src={require('../images/planet.svg')} />
        </>
      )}
    </Shell>
  )
}
const getOffsetHeight = () => {
  const pageHeight = document.body.scrollHeight
  const multiple = pageHeight / 108 - 1
  const remainder = pageHeight % 108
  return multiple * 108 + remainder
}
const Rocket = styled.img`
  position: absolute;
  width: 100%;
  left: 50%;
  max-width: 375px;
  transform: translateX(-50%);
  top: calc(100% - 108px);
`

export default Login
