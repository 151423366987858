import { TimelineMax, Power1 } from 'gsap'
export const coverTransition = ({ direction, seconds, node, pageNode }) => {
  const directionTo = direction === 'up' ? '-100%' : '100%'
  const directionFrom = direction === 'up' ? '100%' : '-100%'

  const wait = seconds / 6
  const half = (seconds - wait) / 2

  return new TimelineMax()
    .set(node, { y: directionFrom })
    .to(node, half, {
      y: '0%',
      ease: Power1.easeInOut
    })
    .set(pageNode, { opacity: 0 })
    .to(
      node,
      half,
      {
        y: directionTo,
        ease: Power1.easeIn
      },
      `+=${wait}`
    )
}
